import React from 'react';
import { Link, graphql } from 'gatsby';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
} from 'react-share';

import Layout from '../components/Layout';
import UniversalCard from '../components/molecules/UniversalCard';
import Newsletter from '../components/molecules/Newsletter';

import imgDate from '../assets/images/pages/blog-inner/date.svg';
import imgPerson from '../assets/images/pages/blog-inner/person.svg';
import imgShare from '../assets/images/pages/blog-inner/share.svg';
import imgFb from '../assets/images/pages/blog-inner/facebook.svg';
import imgTwitter from '../assets/images/pages/blog-inner/twit.svg';
import imgPinterest from '../assets/images/pages/blog-inner/pinterest.svg';
import imgViber from '../assets/images/pages/blog-inner/viber.svg';

import '../assets/styles/pages/blog.scss';

const BlogInnerPage = ({ data }) => {
  const postData = data.prismicArticle.data;
  const similarPosts = data.similarPosts.edges;
  const popularPosts = data.popularPosts.edges;

  const postURL = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout
      title={`${postData?.meta_title?.text || postData?.title?.text} - ELE Barcelona`}
      description={
        postData?.meta_description?.text || `${postData?.content?.text.slice(0, 250)}...`
      }
      metaImage={postData?.image?.url}
    >
      <div className="blog blog--inner">
        <section className="first-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h1 className="c-title-34 title">{postData?.title?.text}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-8">
                <div className="inner-content">
                  <div className="meta-data">
                    {postData?.date && (
                      <div className="item">
                        <img src={imgDate} alt="imgDate" />
                        <span>{postData.date}</span>
                      </div>
                    )}
                    {postData?.author?.document?.data?.name?.text && (
                      <div className="item">
                        <img src={imgPerson} alt="imgPerson" />
                        <span>{postData.author.document.data.name.text}</span>
                      </div>
                    )}
                  </div>
                  <div className="post">
                    {postData?.image && (
                      <img
                        src={postData.image.url}
                        alt={postData?.title?.text}
                        className="main-img"
                      />
                    )}
                  </div>
                  {postData?.content?.html && (
                    <div
                      className="post"
                      dangerouslySetInnerHTML={{ __html: postData.content.html }}
                    />
                  )}
                </div>
                <div className="share-socials">
                  {/* <button className="share-img">
                    <img src={imgShare} alt="imgShare" />
                  </button> */}
                  <FacebookShareButton url={postURL}>
                    <button className="share-btn">
                      <img src={imgFb} alt="imgShare" />
                    </button>
                  </FacebookShareButton>
                  <TwitterShareButton url={postURL}>
                    <button className="share-btn">
                      <img src={imgTwitter} alt="imgShare" />
                    </button>
                  </TwitterShareButton>
                  <PinterestShareButton url={postURL}>
                    <button className="share-btn">
                      <img src={imgPinterest} alt="imgShare" />
                    </button>
                  </PinterestShareButton>
                  <WhatsappShareButton url={postURL}>
                    <button className="share-btn">
                      <img src={imgViber} alt="imgShare" />
                    </button>
                  </WhatsappShareButton>
                </div>
              </div>
              <div className="col-md-5 col-lg-4">
                <div className="popular-courses">
                  <h3 className="popular-courses__title">Puntos populares</h3>
                  {popularPosts.map(({ node }, index) => (
                    <Link to={`/blog/${node.uid}`} key={index}>
                      <UniversalCard
                        title={node?.data?.title?.text}
                        description={`${node?.data?.content?.text.slice(0, 250)}...`}
                        img={node?.data?.image?.url}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="other-posts">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="c-title-34">También le puede gustar</h2>
              </div>
            </div>
            <div className="row top">
              {similarPosts.map(({ node }, index) => (
                <div className="col-lg-4 order-lg-1 order-3" key={index}>
                  <UniversalCard
                    title={node?.data?.title?.text}
                    description={`${node?.data?.content?.text.slice(0, 250)}...`}
                    btn={{ to: `/blog/${node.uid}`, text: 'Leer más' }}
                    img={node?.data?.image?.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="third-section c-section-py-80">
          <div className="container">
            <div className="row cta-block">
              <div className="col">
                <Newsletter />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Article($documentId: String!) {
    prismicArticle(id: { eq: $documentId }) {
      data {
        title {
          text
        }
        content {
          html
          text
        }
        image {
          url
        }
        date(formatString: "MMMM, DD YYYY")
        author {
          document {
            ... on PrismicTeam {
              id
              data {
                name {
                  text
                }
              }
            }
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }

    popularPosts: allPrismicArticle(
      filter: { data: { popular_post: { eq: true } } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }

    similarPosts: allPrismicArticle(
      filter: { id: { ne: $documentId } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
  }
`;

export default BlogInnerPage;
