import React, { useState } from 'react';

import mondayConfig from '../../constants/monday-config';

const EMPTY_SUBSCRIPTION_DATA = {
  subscriberName: '',
  subscriberEmail: '',
};

function Newsletter() {
  const [formData, setFormData] = useState(EMPTY_SUBSCRIPTION_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const MondayData = {
      BOARD_ID: mondayConfig.boardEleBarcelonaLeadsId,
      GROUP_ID: mondayConfig.groupId,
      itemName: formData.subscriberName,
      values: {
        status_3: { label: 'Formulario blog' }, // Source - Fuente
        status_5: mondayConfig.status, // Estatus
        first_name: formData.subscriberName, // Primer nombre
        last_name: '', // Apellidos
        email9: formData.subscriberEmail,
        date5: mondayConfig.date, // contact date - Fecha contacto
      },
    };

    await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(MondayData),
    });

    setFormData(EMPTY_SUBSCRIPTION_DATA);
  };

  return (
    <div className="cta">
      <div className="cta__inner">
        <h3 className="cta__title c-title-34">¡Suscríbete a nuestro boletín!</h3>
        <p className="cta__description">
          Únete a nuestra comunidad de profesores para acceder a recursos didácticos y descuentos en
          nuestros futuros cursos.
        </p>
        <form className="cta__form" onSubmit={handleSubmit}>
          <label>
            <span className="text-under">Primer nombre</span>
            <input
              type="text"
              placeholder="Pablo"
              name="subscriberName"
              required
              value={formData.subscriberName}
              onChange={handleInputChange}
            />
          </label>
          <label>
            <span className="text-under">Correo electrónico</span>
            <input
              type="email"
              placeholder="example@gmail.com"
              name="subscriberEmail"
              required
              value={formData.subscriberEmail}
              onChange={handleInputChange}
            />
          </label>
          <button className="c-btn c-btn--red" type="submit">
            Regístrate ahora
          </button>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
